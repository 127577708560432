<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><router-link :to="{name:'home'}">Home</router-link></li>
          <li><a href="javascript:void(0);">Returns, Cancellations and Delivery Policy</a></li>
        </ul>
      </div>
    </div>
    <section class="section--faq ps-page--business">
      <div class="container">
        <h2 class="page__title">Returns, Cancellations and Delivery Policy</h2>
        <div class="return-content">

          <div class="heading">
             Returns
          </div>
          <p>
           
            whilst every effort is made to ensure the freshness and quality of your items, we appreciate that some items may not be to your satisfaction. For all dry and ambient goods, we ask you to contact the manufacturers of the product. Their contact details can be found on the packaging or on forums online.
            For all fresh and frozen products please contact our customer service department at: Suyeemarket@gmail.com
           </p>
            
            <div class="heading">
            Amendments and Cancellations
             </div>
            <p>
            You may cancel your order up to 12hours on the day of your delivery. A cancellation fee may apply to orders cancelled after this time.
            </p>

            <div class="heading">
            Substitutions
             </div>
            <p>
            Every effort will be made to ensure that all your orders are fulfilled, however the possibility could arise that your selected items or brands may be out of stock or discontinued. In this event, a suitable alternative product or brand will be issued automatically, unless otherwise specified. As a customer you will be notified of the changes prior to delivery.
            </p>
            <div class="heading">
            Delivery Charges and Coverage
             </div>
            <p>
            The minimum delivery charge is differ from place to place in the current situations.
            </p>
        </div>
      </div>
    </section>
  </main>
</template>

<style>
  .return-content{
    margin: 20px 0;
  }
  .return-content .heading {
    color: #000000;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 3px;
}
.return-content ul {
    list-style: disc;
    padding-left: 20px;
}
</style>